import { ComputedRef, ref, watch } from 'vue';
import BigNumber from 'bignumber.js';
import { getTokenBalance } from '@/composables/token/useTokenBalance';
import { fetchExactLpWeiAmountAfterDeposit } from '@/helpers/portfolioDeposit.helper';
import { FilledTokensObject, Portfolio, TokenInfo } from '@/sdk/entities/portfolio';
import { compareTokenAddresses, fromWei, toFixedWei } from '@/sdk/utils';
import { SelectedPortfolioTokens } from '@/store/modules/portfolios/models/selected-portfolio-tokens.interface';
import { BIG_ONE, BIG_ZERO, max, min } from '@/utils/bigNumber';
import { pCancelable } from '@/utils/promise';
import { useEasyModeForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeForm';
import { EasyModeAddLiquidityForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-form';
import { EasyModeAddLiquidityToken } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-token';
import { normalizeWeiByMinDecimalsWhenExistCardanoToken } from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { TOKEN_NOTICE_TYPES } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/common/constants/TOKEN_NOTICE_TYPES';
import { Token } from '@/sdk/entities/token';

const {
  easyModeForm: easyModeCommonForm,
  baseOrLpTokenAmountWei,
  selectedTokens: selectedCommonTokens,
  getPortfolioAvailableTokens,
  calculateHowManyTokensCanBridgeFromCardano,
  isValidRestAmount,
} = useEasyModeForm();
const selectedTokens = selectedCommonTokens as ComputedRef<EasyModeAddLiquidityToken[]>;
const easyModeForm = easyModeCommonForm as EasyModeAddLiquidityForm;
const feeRequestAbortRef = ref<(() => void) | null>(null);

watch(
  () => easyModeForm.lossPercent,
  () => {
    if (easyModeForm.withinBalances && easyModeForm.lossPercent?.gt(0)) {
      setAllTokensNotDraggable();
    }
  },
);

/**
 * returns [W[]] limits in tokens wei units
 * @param weiValueToDeposit [W] in base currency wei units
 */
function calculateDepositLimits(portfolio: Portfolio, weiValueToDeposit: BigNumber): BigNumber[] {
  const portfolioValueWei = portfolio.totalValueWithProtocolFee;

  return getPortfolioAvailableTokens(portfolio).map(tokenInfo => {
    // Ui = (V + deposit) * Wi - Ri
    // In BASE WEI
    const lackOfTokenInBaseWei = toFixedWei(
      portfolioValueWei
        .plus(weiValueToDeposit)
        .multipliedBy(tokenInfo.targetWeight)
        .minus(tokenInfo.baseTokenAmountEquivalent),
    );
    // In BASE WEI / TOKEN WEI
    const depositPriceInWei = tokenInfo
      .getDepositPrice()
      .shiftedBy(portfolio.baseToken.decimals - tokenInfo.token.decimals);
    // BASE WEI / (BASE WEI / TOKEN WEI) => TOKEN WEI
    const lackOfTokenInTokenWei = toFixedWei(lackOfTokenInBaseWei.div(depositPriceInWei));

    // In TOKEN WEI
    const depositLimitInTokenWei = max(lackOfTokenInTokenWei, 0);

    loggingCalculateDepositLimit(
      tokenInfo,
      depositLimitInTokenWei,
      portfolioValueWei,
      weiValueToDeposit,
      lackOfTokenInBaseWei,
      lackOfTokenInTokenWei,
    );

    // In TOKEN WEI
    return depositLimitInTokenWei;
  });
}

async function calculateLimits() {
  resetFormBeforeCalculation();

  if (checkAndModifyFormIfCannotCalculate()) {
    return;
  }

  // rawLimits in token wei units
  const rawLimits = calculateDepositLimits(easyModeForm.portfolio, baseOrLpTokenAmountWei.value);
  resetLimitsAndSetSelectTokenNotices(rawLimits);

  if (!selectedTokens.value.length) {
    setUndefinedAddLiquidityFee();
    return;
  }

  // limitsWithBalances in token wei units
  const limitsWithBalances = getPortfolioAvailableTokens(easyModeForm.portfolio).map(
    (tokenInfo, index) =>
      min(
        rawLimits[index],
        getTokenBalance(tokenInfo.token, {
          inWei: true,
          subTxFeeFromNativeBalance: true,
          countHowManyCanBridgeFromCardano: calculateHowManyTokensCanBridgeFromCardano(),
        }),
      ),
  );

  const firstIterationResult = firstIteration(limitsWithBalances);
  easyModeForm.restAmountToDistributeAfterFirstIteration = fromWei(
    firstIterationResult.restAmountToDeposit,
    easyModeForm.portfolio.baseToken.decimals,
  );

  // NOTE: If rest is zero after first iteration, then calculate will finish.
  if (firstIterationResult.restAmountToDeposit.isZero()) {
    easyModeForm.lossPercent = BIG_ZERO;
    easyModeForm.impactError = false;

    // In BASE WEI / LP WEI
    // NOTE: Changed `LP_TOKEN_DECIMALS` -> easyModeForm.lpToken.decimals
    const lpTokenPriceBaseInWei = easyModeForm.portfolio.lpTokenPriceBase.shiftedBy(
      easyModeForm.portfolio.baseToken.decimals - easyModeForm.lpToken.decimals,
    );
    // BASE WEI / (BASE WEI / LP WEI) => LP WEI
    const lpAmountOutWei = toFixedWei(baseOrLpTokenAmountWei.value.div(lpTokenPriceBaseInWei));
    easyModeForm.lpAmountOut = new BigNumber(lpAmountOutWei);

    normalizeLimitsByMinDecimals(firstIterationResult.limits);
    await updateAddLiquidityFee(firstIterationResult.limits, rawLimits);
    updateTokensLimits(firstIterationResult.limits);

    loggingResultForAddLiquidityAfterFirstIteration(
      easyModeForm,
      firstIterationResult,
      lpAmountOutWei,
    );
    return;
  }

  const secondIterationResult = secondIteration(
    firstIterationResult.limits,
    firstIterationResult.restAmountToDeposit,
  );

  const totalLimits = firstIterationResult.limits.map((limit, index) =>
    limit.plus(secondIterationResult.limits[index]),
  );

  // in base token decimals
  easyModeForm.restAmount = secondIterationResult.restAmountToDeposit;
  if (isValidRestAmount.value) {
    normalizeLimitsByMinDecimals(totalLimits);
    await updateAddLiquidityFee(totalLimits, rawLimits);
  } else {
    console.warn(
      '[ADD LIQUIDITY] After calculate limits, we have invalid rest amount : ',
      easyModeForm.restAmount.shiftedBy(-easyModeForm.portfolio.baseToken.decimals).toString(),
    );
    setUndefinedAddLiquidityFee();
  }

  // NOTE: For `within balances` here calculation will finish.
  if (easyModeForm.withinBalances) {
    normalizeLimitsByMinDecimals(totalLimits);
    updateTokensLimits(totalLimits);

    loggingResultForAddLiquidityWithBalances(easyModeForm, totalLimits);
    return;
  }

  const thirdIterationResult = thirdIteration(rawLimits);

  const checkedTokensBalances = selectedTokens.value.map(formToken => {
    const tokenInfo = getTokenInfo(formToken.address);

    return getTokenBalance(tokenInfo.token, {
      inWei: true,
      subTxFeeFromNativeBalance: true,
      countHowManyCanBridgeFromCardano: calculateHowManyTokensCanBridgeFromCardano(),
    });
  });

  const amountsToBuy = thirdIterationResult.limits.map((limit, index) =>
    max(limit.minus(checkedTokensBalances[index]), 0),
  );

  normalizeLimitsByMinDecimals(totalLimits);
  updateTokensLimits(totalLimits, { allTokensRawLimits: rawLimits, amountsToBuy });

  loggingResultForAddLiquidityWithoutBalances(easyModeForm, thirdIterationResult, totalLimits);
}

/**
 *
 * @param currentLimits limits for selected tokens only in token wei units
 * @param amountToDistribute amount in base currency wei units
 */
function distributeAmount(
  currentLimits: BigNumber[],
  amountToDistribute: BigNumber,
  useProportion = false,
): {
  limits: BigNumber[];
  restAmountToDeposit: BigNumber;
} {
  const distributeProportionByNum = currentLimits.filter(limit => limit.gt(0)).length;
  const proportion =
    useProportion && distributeProportionByNum ? BIG_ONE.div(distributeProportionByNum) : undefined;
  let restAmountToDistributeInBase = amountToDistribute;
  let limitNotReachedCounter = 0;

  // In TOKENS WEI
  const limits = selectedTokens.value.map((formToken, index) => {
    if (!useProportion && restAmountToDistributeInBase.isZero()) {
      return BIG_ZERO;
    }

    const tokenInfo = getTokenInfo(formToken.address);
    // In BASE WEI / TOKEN WEI
    const depositPriceInWei = tokenInfo
      .getDepositPrice()
      .shiftedBy(tokenInfo.baseToken.decimals - tokenInfo.token.decimals);

    const valueToCompareWithInTokenWei = proportion
      ? amountToDistribute.multipliedBy(proportion).div(depositPriceInWei)
      : restAmountToDistributeInBase.div(depositPriceInWei);

    const limit = min(currentLimits[index], valueToCompareWithInTokenWei);
    if (limit.eq(valueToCompareWithInTokenWei)) {
      limitNotReachedCounter++;
    }

    restAmountToDistributeInBase = restAmountToDistributeInBase
      .minus(limit.multipliedBy(depositPriceInWei))
      .decimalPlaces(easyModeForm.portfolio.baseToken.decimals);
    return limit;
  });

  const noneLimitReached =
    useProportion && limitNotReachedCounter && limitNotReachedCounter === distributeProportionByNum;

  if (noneLimitReached) {
    const formToken = selectedTokens.value[0];
    const tokenInfo = getTokenInfo(formToken.address);
    // In BASE WEI / TOKEN WEI
    const depositPriceInWei = tokenInfo
      .getDepositPrice()
      .shiftedBy(tokenInfo.baseToken.decimals - tokenInfo.token.decimals);

    limits[0] = limits[0].plus(restAmountToDistributeInBase.div(depositPriceInWei));
    restAmountToDistributeInBase = BIG_ZERO;
  }

  return {
    limits,
    restAmountToDeposit: restAmountToDistributeInBase,
  };
}

/**
 *
 * @param allTokensLimitsWithBalance limits in token wei units
 */
function firstIteration(allTokensLimitsWithBalance: BigNumber[]): {
  limits: BigNumber[];
  restAmountToDeposit: BigNumber;
} {
  const selectedTokensLimits = getSelectedTokensLimits(allTokensLimitsWithBalance);

  const proportionDistribution = distributeAmount(
    selectedTokensLimits,
    baseOrLpTokenAmountWei.value,
    true,
  );

  loggingIterationDistribution('firstIteration', 'proportion distribution', proportionDistribution);

  if (easyModeForm.withinBalances) {
    if (proportionDistribution.restAmountToDeposit.isZero()) {
      setAllTokensNotDraggable();
    } else {
      updateTokensDraggableProperty(selectedTokensLimits, proportionDistribution.limits);
    }
  }

  const restLimits = selectedTokensLimits.map((limit, index) =>
    limit.minus(proportionDistribution.limits[index]),
  );
  const restAmounts = distributeAmount(restLimits, proportionDistribution.restAmountToDeposit);
  const totalLimits = restAmounts.limits.map((limit, index) =>
    limit.plus(proportionDistribution.limits[index]),
  );

  loggingIterationDistribution('firstIteration', 'rest amounts distribution', restAmounts);

  loggingIterationDistribution('firstIteration', 'total amounts', {
    limits: totalLimits,
    restAmountToDeposit: restAmounts.restAmountToDeposit,
  });

  return {
    limits: totalLimits,
    restAmountToDeposit: restAmounts.restAmountToDeposit,
  };
}

/**
 *
 * @param limitsAfterFirstIteration limits in token wei units
 * @param restAmount amount in base currency wei units
 */
function secondIteration(
  limitsAfterFirstIteration: BigNumber[],
  restAmount: BigNumber,
): {
  limits: BigNumber[];
  restAmountToDeposit: BigNumber;
} {
  const selectedTokensAndLimitsSortedByVirtualReserves = selectedTokens.value
    .map((formToken, index) => {
      const tokenInfo = getTokenInfo(formToken.address);

      const Fx = min(
        tokenInfo.getDepositPrice().div(tokenInfo.depositEMAPrice),
        tokenInfo.depositEMAPrice.div(tokenInfo.getDepositPrice()),
      );

      const balance = getTokenBalance(tokenInfo.token, {
        inWei: true,
        subTxFeeFromNativeBalance: true,
        countHowManyCanBridgeFromCardano: calculateHowManyTokensCanBridgeFromCardano(),
      });
      const limit = balance.minus(limitsAfterFirstIteration[index]);

      return { formToken, limit, reserve: Fx.multipliedBy(tokenInfo.baseTokenAmountEquivalent) };
    })
    .sort((a, b) => b.reserve.minus(a.reserve).toNumber());

  let restAmountToDistributeInBaseWei = restAmount;

  // limits in TOKENS WEI
  const limitsAndFormTokens = selectedTokensAndLimitsSortedByVirtualReserves.map(tokenAndLimit => {
    if (restAmountToDistributeInBaseWei.isZero()) {
      return { limit: BIG_ZERO, formToken: tokenAndLimit.formToken };
    }

    const tokenInfo = getTokenInfo(tokenAndLimit.formToken.address);
    // In BASE WEI / TOKEN WEI
    const depositPriceInWei = tokenInfo
      .getDepositPrice()
      .shiftedBy(tokenInfo.baseToken.decimals - tokenInfo.token.decimals);
    // BASE WEI / (BASE WEI / TOKEN WEI) => TOKEN WEI
    const restAmountToDistributeInCurrentTokenWei =
      restAmountToDistributeInBaseWei.div(depositPriceInWei);

    // In TOKEN WEI
    const limit = min(tokenAndLimit.limit, restAmountToDistributeInCurrentTokenWei);
    restAmountToDistributeInBaseWei = restAmountToDistributeInBaseWei
      .minus(limit.multipliedBy(depositPriceInWei))
      .decimalPlaces(tokenInfo.baseToken.decimals);
    return { limit, formToken: tokenAndLimit.formToken };
  });

  const limitsInSelectedTokensOrder = selectedTokens.value.map(
    formToken =>
      limitsAndFormTokens.find(tokenAndLimit =>
        compareTokenAddresses(tokenAndLimit.formToken.address, formToken.address),
      )!.limit,
  );

  return {
    limits: limitsInSelectedTokensOrder, // In TOKENS WEI
    restAmountToDeposit: restAmountToDistributeInBaseWei, // In BASE WEI
  };
}

/**
 *
 * @param globalLimitsForAllTokens limits in token wei units
 */
function thirdIteration(globalLimitsForAllTokens: BigNumber[]): {
  limits: BigNumber[];
  restAmountToDeposit: BigNumber;
} {
  const selectedTokensLimits = getSelectedTokensLimits(globalLimitsForAllTokens);

  const proportionDistribution = distributeAmount(
    selectedTokensLimits,
    baseOrLpTokenAmountWei.value,
    true,
  );

  loggingIterationDistribution('thirdIteration', 'proportion distribution', proportionDistribution);

  if (proportionDistribution.restAmountToDeposit.isZero()) {
    setAllTokensNotDraggable();
  } else {
    updateTokensDraggableProperty(selectedTokensLimits, proportionDistribution.limits);
  }

  const restLimits = selectedTokensLimits.map((limit, index) =>
    limit.minus(proportionDistribution.limits[index]),
  );
  const restAmounts = distributeAmount(restLimits, proportionDistribution.restAmountToDeposit);
  const totalLimits = restAmounts.limits.map((limit, index) =>
    limit.plus(proportionDistribution.limits[index]),
  );

  loggingIterationDistribution('thirdIteration', 'rest amounts distribution', restAmounts);

  loggingIterationDistribution('thirdIteration', 'total amounts', {
    limits: totalLimits,
    restAmountToDeposit: restAmounts.restAmountToDeposit,
  });

  return {
    limits: totalLimits,
    restAmountToDeposit: restAmounts.restAmountToDeposit,
  };
}

function getTokenInfo(tokenAddress: string): TokenInfo {
  return getPortfolioAvailableTokens(easyModeForm.portfolio).find(tokenInfo =>
    compareTokenAddresses(tokenInfo.tokenAddress, tokenAddress),
  )!;
}

function getTokenInfoIndex(tokenAddress: string): number {
  return getPortfolioAvailableTokens(easyModeForm.portfolio).findIndex(tokenInfo =>
    compareTokenAddresses(tokenInfo.tokenAddress, tokenAddress),
  );
}

/**
 *
 * @param limits limits in token wei units
 */
function updateTokensLimits(
  limits: BigNumber[],
  buyNoticeInfo?: {
    allTokensRawLimits: BigNumber[]; // in token wei units
    amountsToBuy?: BigNumber[]; // in token wei units
  },
): void {
  let shouldShowAddNotice = false;

  if (buyNoticeInfo) {
    const selectedTokensLimits = getSelectedTokensLimits(buyNoticeInfo.allTokensRawLimits);
    const limitsSumInBaseWei = selectedTokensLimits
      .map((limit, index) => {
        const tokenInfo = getTokenInfo(selectedTokens.value[index].address);

        return limit
          .multipliedBy(tokenInfo.getDepositPrice())
          .shiftedBy(tokenInfo.baseToken.decimals - tokenInfo.token.decimals); // to base decimals
      })
      .reduce((acc, limitInBase) => acc.plus(limitInBase), BIG_ZERO);
    shouldShowAddNotice = limitsSumInBaseWei.dp(0).gte(baseOrLpTokenAmountWei.value.dp(0));
  }

  selectedTokens.value.forEach((formToken, index) => {
    const token = getTokenInfo(formToken.address).token;
    const decimals = token.decimals;
    formToken.amount = fromWei(limits[index], decimals);

    if (buyNoticeInfo && shouldShowAddNotice) {
      const amountToBuy = buyNoticeInfo.amountsToBuy?.[index]
        ? fromWei(buyNoticeInfo.amountsToBuy[index], decimals)
        : BIG_ZERO;
      if (amountToBuy.gt(0)) {
        formToken.notice = {
          type: TOKEN_NOTICE_TYPES.ADD,
          amount: amountToBuy,
        };
      }
    }
  });
}

/**
 *
 * @param rawLimits limits in token wei units
 */
function resetLimitsAndSetSelectTokenNotices(rawLimits: BigNumber[]): void {
  easyModeForm.tokens.forEach(formToken => {
    const portfolioTokenIndex = getTokenInfoIndex(formToken.address);
    const portfolioToken = getTokenInfo(formToken.address);

    formToken.amount = BIG_ZERO;
    formToken.notice = undefined;
    formToken.draggable = true;
    formToken.noLossLimit = fromWei(rawLimits[portfolioTokenIndex], portfolioToken.token.decimals);

    const tokenInfo = getTokenInfo(formToken.address);
    const balance = getTokenBalance(tokenInfo.token, {
      subTxFeeFromNativeBalance: true,
      countHowManyCanBridgeFromCardano: calculateHowManyTokensCanBridgeFromCardano(),
    });

    const canSelectToken = balance.gt(0) || !easyModeForm.withinBalances;

    if (!canSelectToken) {
      formToken.notice = {
        type: TOKEN_NOTICE_TYPES.ZERO_BALANCE,
      };
      return;
    }

    if (rawLimits[portfolioTokenIndex].isZero()) {
      formToken.notice = {
        type: TOKEN_NOTICE_TYPES.ZERO_LIMIT,
      };
    }
  });
}

function setSelectTokenNotices(rawLimits: BigNumber[]): void {
  easyModeForm.tokens.forEach(formToken => {
    const portfolioTokenIndex = getTokenInfoIndex(formToken.address);

    if (!formToken.checked && rawLimits[portfolioTokenIndex].gt(0)) {
      formToken.notice = {
        type: TOKEN_NOTICE_TYPES.SELECT,
      };
    }
  });
}

/**
 *
 * @param selectedTokensWeiAmounts amounts in token wei units
 * @param allTokensRawLimits limits in token wei units
 */
async function updateAddLiquidityFee(
  selectedTokensWeiAmounts: BigNumber[],
  allTokensRawLimits: BigNumber[],
) {
  feeRequestAbortRef.value?.();
  easyModeForm.lossPercent = null;
  easyModeForm.crossChainLossPercent = null;
  easyModeForm.impactError = null;

  const feeRequest = pCancelable(fetchAddLiquidityFee(selectedTokensWeiAmounts));
  feeRequestAbortRef.value = feeRequest.abort;
  return feeRequest.promise
    .then(result => {
      easyModeForm.lossPercent = result.lossPercent;
      easyModeForm.crossChainLossPercent = result.crossChainLossPercent;
      easyModeForm.impactError = result.impactError;
      easyModeForm.lpAmountOut = result.lpAmountOut; // in LP wei

      if (result.lossPercent.gt(0)) {
        setSelectTokenNotices(allTokensRawLimits);
      }
    })
    .catch(e => {
      if (!e.toString().includes('Promise has been canceled')) {
        throw e;
      }
    });
}

function updateTokensDraggableProperty(
  selectedTokensLimits: BigNumber[],
  proportionLimits: BigNumber[],
): void {
  selectedTokens.value.forEach((formToken, index) => {
    formToken.draggable = !selectedTokensLimits[index].eq(proportionLimits[index]);
  });
}

function setAllTokensNotDraggable(): void {
  selectedTokens.value.forEach(formToken => {
    formToken.draggable = false;
  });
}

function setUndefinedAddLiquidityFee(): void {
  easyModeForm.lossPercent = undefined;
  easyModeForm.crossChainLossPercent = undefined;
  easyModeForm.impactError = undefined;
  easyModeForm.lpAmountOut = undefined;
}

function checkAndModifyFormIfCannotCalculate(): boolean {
  if (baseOrLpTokenAmountWei.value.isZero()) {
    easyModeForm.tokens.forEach(token => {
      token.amount = BIG_ZERO;
      token.noLossLimit = BIG_ZERO;
      token.notice = undefined;
    });
    setUndefinedAddLiquidityFee();

    return true;
  }

  return false;
}

function resetFormBeforeCalculation(): void {
  feeRequestAbortRef.value?.();
  feeRequestAbortRef.value = null;
  easyModeForm.restAmount = BIG_ZERO;
  easyModeForm.lpAmountOut = undefined;
  easyModeForm.restAmountToDistributeAfterFirstIteration = BIG_ZERO;
}

/**
 *
 * @param selectedTokensWeiAmounts amounts in token wei units
 */
async function fetchAddLiquidityFee(selectedTokensWeiAmounts: BigNumber[]): Promise<{
  lossPercent: BigNumber;
  impactError: boolean;
  lpAmountOut: BigNumber;
  crossChainLossPercent?: BigNumber;
}> {
  const portfolioLikeTokens: SelectedPortfolioTokens = Object.fromEntries(
    selectedTokensWeiAmounts.map((weiAmount, index) => {
      const selectedTokenAddress = selectedTokens.value[index].address;
      const tokenInfo = getTokenInfo(selectedTokenAddress);

      const filledFormToken: FilledTokensObject = {
        value: fromWei(weiAmount, tokenInfo.token.decimals).toFixed(),
        hasError: false,
        hasOnlyBalanceError: false,
        checked: true,
      };

      return [selectedTokenAddress, filledFormToken];
    }),
  );

  const baseTokenWeiEnteredAmount = baseOrLpTokenAmountWei.value;

  let impactError = false;
  let lossPercent = BIG_ZERO;
  let lpAmountOutInWei = BIG_ZERO;
  let crossChainLossPercent: BigNumber | undefined = undefined;

  try {
    const { estimatedAmountLPInWei: lpAmountOutWei, crossChainFeeInLPWei: crossChainFee } =
      await fetchExactLpWeiAmountAfterDeposit(
        easyModeForm.portfolio,
        portfolioLikeTokens,
        easyModeForm,
      );
    lpAmountOutInWei = BigNumber(lpAmountOutWei);
    const crossChainFeeInLPWei = BigNumber(crossChainFee ?? 0);
    // BASE WEI / LP WEI
    // NOTE: Changed `LP_TOKEN_DECIMALS` -> easyModeForm.lpToken.decimals
    const lpTokenPriceBaseInWei = easyModeForm.portfolio.lpTokenPriceBase.shiftedBy(
      easyModeForm.portfolio.baseToken.decimals - easyModeForm.lpToken.decimals,
    );
    // LP WEI * (BASE WEI / LP WEI) => BASE WEI
    const baseTokenWeiEquivalentAmountOutWithCrossChainFee = lpAmountOutInWei
      .plus(crossChainFeeInLPWei)
      .multipliedBy(lpTokenPriceBaseInWei);
    const crossChainFeeInBaseWei = crossChainFeeInLPWei.multipliedBy(lpTokenPriceBaseInWei);

    lossPercent = max(
      BIG_ONE.minus(
        baseTokenWeiEquivalentAmountOutWithCrossChainFee.div(baseTokenWeiEnteredAmount),
      ).multipliedBy(100),
      0,
    );
    crossChainLossPercent = crossChainFee
      ? max(crossChainFeeInBaseWei.div(baseTokenWeiEnteredAmount).multipliedBy(100), BIG_ZERO)
      : undefined;

    // LOSS
    loggingLossCalculation(
      lpAmountOutInWei,
      lpTokenPriceBaseInWei,
      lossPercent,
      baseTokenWeiEnteredAmount,
      easyModeForm.lpToken,
    );

    // CROSS CHAIN LOSS
    loggingCrossChainLossCalculation(
      lpAmountOutInWei,
      lpTokenPriceBaseInWei,
      crossChainLossPercent,
      crossChainFeeInLPWei,
      baseTokenWeiEnteredAmount,
      baseTokenWeiEquivalentAmountOutWithCrossChainFee,
      easyModeForm.lpToken,
    );
  } catch (e) {
    console.debug(e);
    impactError = true;
  }

  return {
    lossPercent,
    impactError,
    lpAmountOut: lpAmountOutInWei,
    crossChainLossPercent,
  };
}

export function useEasyModeAddLiquidityCalculation() {
  return {
    calculateLimits,
  };
}

function getSelectedTokensLimits(allTokensLimits: BigNumber[]): BigNumber[] {
  return selectedTokens.value.map(formToken => {
    const portfolioTokenIndex = getTokenInfoIndex(formToken.address);

    return allTokensLimits[portfolioTokenIndex];
  });
}

function normalizeLimitsByMinDecimals(limits: BigNumber[]) {
  selectedTokens.value.forEach((formToken, index) => {
    const token = getTokenInfo(formToken.address).token;
    limits[index] = normalizeWeiByMinDecimalsWhenExistCardanoToken(limits[index], token);
  });
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].EASY_MODE_ADD_LIQUIDITY;
}

function loggingIterationDistribution(
  iterationName: string,
  distributionName: string,
  distribution: {
    limits: BigNumber[];
    restAmountToDeposit: BigNumber;
  },
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed(`[ADD LIQUIDITY] ${iterationName} | ${distributionName} `);
  console.table(
    selectedTokens.value.map((formToken, index) => {
      const tokenInfo = getTokenInfo(formToken.address);
      const limit = distribution.limits[index];
      return {
        'amount [TOKEN WEI]': limit.toString(),
        'amount [TOKEN]': limit.shiftedBy(-tokenInfo.token.decimals).toString(),
        token: tokenInfo.token.symbol,
        decimals: tokenInfo.token.decimals,
      };
    }),
  );
  console.log(
    `${iterationName} | ${distributionName} | rest amount [BASE WEI] : `,
    distribution.restAmountToDeposit.toString(),
  );
  console.groupEnd();
}

function loggingLossCalculation(
  lpAmountOutInWei: BigNumber,
  lpTokenPriceBaseInWei: BigNumber,
  lossPercent: BigNumber,
  baseTokenWeiEnteredAmount: BigNumber,
  lpToken: Token,
) {
  if (isLoggingDisabled()) return;

  // LP WEI * (BASE WEI / LP WEI) => BASE WEI
  const baseTokenWeiEquivalentAmountOut = toFixedWei(
    lpAmountOutInWei.multipliedBy(lpTokenPriceBaseInWei),
  );

  console.groupCollapsed('[ADD LIQUIDITY] loss percent : ', lossPercent.toString());
  const baseToken = easyModeForm.portfolio.baseToken;
  console.log(`BASE :  ${baseToken.symbol} | ${baseToken.decimals}`);
  console.log('lpTokenPriceBase [ BASE WEI / LP WEI ] : ', lpTokenPriceBaseInWei.toString());
  console.log(
    'lpTokenPriceBase [ BASE / LP ] : ',
    easyModeForm.portfolio.lpTokenPriceBase.toString(),
  );
  console.log('priceInUSD [ USD / BASE ] : ', easyModeForm.portfolio.priceInUSD.toString());
  console.log('lpAmountOutWei [ LP WEI ]: ', lpAmountOutInWei.toString());
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
  console.log('lpAmountOutWei [ LP ]: ', lpAmountOutInWei.shiftedBy(-lpToken.decimals).toString());
  console.log(
    'baseTokenWeiEquivalentAmountOut [ BASE WEI ] : ',
    baseTokenWeiEquivalentAmountOut.toString(),
  );
  console.log('baseTokenWeiEnteredAmount [ BASE WEI ] : ', baseTokenWeiEnteredAmount.toString());
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
  console.log(
    'lpEnteredAmount [ LP ]: ',
    baseTokenWeiEnteredAmount.div(lpTokenPriceBaseInWei).shiftedBy(-lpToken.decimals).toString(),
  );
  console.groupEnd();
}

function loggingCrossChainLossCalculation(
  lpAmountOutInWei: BigNumber,
  lpTokenPriceBaseInWei: BigNumber,
  crossChainLossPercent: BigNumber | undefined,
  crossChainFeeInLPWei: BigNumber,
  baseTokenWeiEnteredAmount: BigNumber,
  baseTokenWeiEquivalentAmountOutWithCrossChainFee,
  lpToken: Token,
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed(
    '[ADD LIQUIDITY] cross chain loss percent : ',
    crossChainLossPercent?.toString(),
  );
  console.log('LP out amount [ LP WEI ] : ', lpAmountOutInWei.toString());
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
  console.log('LP out amount [ LP ] : ', lpAmountOutInWei.shiftedBy(-lpToken.decimals).toString());
  console.log(
    'cross chain fee [ LP WEI ] : ',
    crossChainFeeInLPWei ? BigNumber(crossChainFeeInLPWei).toString() : crossChainFeeInLPWei,
  );
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
  console.log(
    'cross chain fee [ LP ] : ',
    crossChainFeeInLPWei
      ? BigNumber(crossChainFeeInLPWei).shiftedBy(-lpToken.decimals).toString()
      : crossChainFeeInLPWei,
  );
  console.log(
    'LP out amount + cross chain fee [ LP WEI ] : ',
    lpAmountOutInWei.plus(crossChainFeeInLPWei ?? 0).toString(),
  );
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
  console.log(
    'LP out amount + cross chain fee [ LP ] : ',
    lpAmountOutInWei
      .plus(crossChainFeeInLPWei ?? 0)
      .shiftedBy(-lpToken.decimals)
      .toString(),
  );
  console.log(
    'LP entered amount [ LP WEI ]: ',
    baseTokenWeiEnteredAmount.div(lpTokenPriceBaseInWei).toString(),
  );
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> lpToken.decimals
  console.log(
    'LP entered amount [ LP ]: ',
    baseTokenWeiEnteredAmount.div(lpTokenPriceBaseInWei).shiftedBy(-lpToken.decimals).toString(),
  );
  console.log(
    'baseTokenWeiEquivalentAmountOut + cross chain fee [ BASE WEI ] : ',
    baseTokenWeiEquivalentAmountOutWithCrossChainFee.toString(),
  );
  console.groupEnd();
}

function loggingCalculateDepositLimit(
  tokenInfo: TokenInfo,
  depositLimitInTokenWei: BigNumber,
  portfolioValueWei: BigNumber,
  weiValueToDeposit: BigNumber,
  lackOfTokenInBaseWei: BigNumber,
  lackOfTokenInTokenWei: BigNumber,
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed(
    `[ADD LIQUIDITY] Calculate deposit limit. Limit [${tokenInfo.token.symbol} | ${tokenInfo.token.decimals}] WEI : `,
    depositLimitInTokenWei.toString(),
    ` | ${depositLimitInTokenWei.shiftedBy(-tokenInfo.token.decimals)}`,
  );
  console.table({
    'portfolioValue [BASE WEI]': portfolioValueWei.toString(),
    'valueToDeposit [BASE WEI]': weiValueToDeposit.toString(),
    targetWeight: tokenInfo.targetWeight.toString(),
    'tokenAmount [BASE WEI]': tokenInfo.baseTokenAmountEquivalent.toString(),
    'depositPrice [BASE / TOKEN]': tokenInfo.getDepositPrice().toString(),
    '=> lackOfToken [BASE WEI]': lackOfTokenInBaseWei.toString(),
    '=> lackOfToken [TOKEN WEI]': lackOfTokenInTokenWei.toString(),
  });
  console.groupEnd();
}

function loggingResultForAddLiquidityAfterFirstIteration(
  easyModeForm: EasyModeAddLiquidityForm,
  firstIterationResult: {
    limits: BigNumber[];
    restAmountToDeposit: BigNumber;
  },
  lpAmountOutWei: BigNumber,
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed('RESULT FOR ADD LIQUIDITY: rest amount after first iteration is zero.');
  console.log('token amount [BASE WEI] : ', baseOrLpTokenAmountWei.value.toString());
  console.log('lpTokenPriceBase [BASE / LP]: ', easyModeForm.portfolio.lpTokenPriceBase.toString());
  console.log('calc lpAmountOut [LP WEI] : ', lpAmountOutWei.toString());
  console.log('estimate lpAmountOut [LP WEI]: ', easyModeForm.lpAmountOut?.toString());
  console.log('Limits');
  console.table(
    selectedTokens.value.map((token, index) => {
      const tokenInfo = getTokenInfo(token.address);
      const limit = firstIterationResult.limits[index].toString();
      return {
        limit,
        token: tokenInfo.token.symbol,
        decimals: tokenInfo.token.decimals,
      };
    }),
  );
  console.groupEnd();
}

function loggingResultForAddLiquidityWithBalances(
  easyModeForm: EasyModeAddLiquidityForm,
  totalLimits: BigNumber[],
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed('RESULT FOR ADD LIQUIDITY WITH BALANCES');
  console.log(
    'restAmount : ',
    easyModeForm.restAmount?.toString(),
    ` in ${easyModeForm.portfolio.baseToken.symbol}`,
  );
  console.log('Limits');
  console.table(
    selectedTokens.value.map((token, index) => {
      const tokenInfo = getTokenInfo(token.address);
      const limit = totalLimits[index].toString();
      return {
        limit,
        token: tokenInfo.token.symbol,
        decimals: tokenInfo.token.decimals,
      };
    }),
  );
  console.groupEnd();
}

function loggingResultForAddLiquidityWithoutBalances(
  easyModeForm: EasyModeAddLiquidityForm,
  thirdIterationResult: {
    limits: BigNumber[];
    restAmountToDeposit: BigNumber;
  },
  totalLimits: BigNumber[],
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed('RESULT FOR ADD LIQUIDITY WITHOUT BALANCES');
  console.log(
    'restAmount : ',
    thirdIterationResult.restAmountToDeposit.toString(),
    ` in ${easyModeForm.portfolio.baseToken.symbol}`,
  );
  console.log('Limits');
  console.table(
    selectedTokens.value.map((token, index) => {
      const tokenInfo = getTokenInfo(token.address);
      const limit = totalLimits[index].toString();
      return {
        limit,
        token: tokenInfo.token.symbol,
        decimals: tokenInfo.token.decimals,
      };
    }),
  );
  console.groupEnd();
}
