import { computed, watch, ref } from 'vue';
import { useLayout } from '@/store/modules/layout/useLayout';

export const documentStyles = ref(getComputedStyle(document.documentElement));
const theme = computed(() => useLayout().theme);

export const CHART_COLOR_NAMES = {
  PINK: '--chart-pink',
  LIGHT_GREEN: '--chart-light-green',
  BLUE: '--chart-blue',
  ORANGE: '--chart-orange',
  DARK_BLUE: '--chart-dark-blue',
  GREEN: '--chart-green',
  DARK_RED: '--chart-dark-red',
  LIGHT_ORANGE: '--chart-light-orange',
  RED: '--chart-red',
  PURPLE: '--chart-purple',
  YELLOW: '--chart-yellow',
  DARK_GREEN: '--chart-dark-green',
  DARK_PINK: '--chart-dark-pink',
  LIGHT_BLUE: '--chart-light-blue',
  DARK_ORANGE: '--chart-dark-orange',
};

export const chartColors = computed(() => {
  return Object.keys(CHART_COLOR_NAMES).map(name =>
    documentStyles.value.getPropertyValue(CHART_COLOR_NAMES[name]),
  );
});

export const chartItemColor = index => {
  const maxColorIndex = chartColors.value.length - 1;
  return index > maxColorIndex
    ? chartColors.value[Math.abs(chartColors.value.length - index)]
    : chartColors.value[index];
};
watch(theme, () => (documentStyles.value = getComputedStyle(document.documentElement)));
