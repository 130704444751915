import { computed, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { MODULE_NAMES } from '@/store';
import { PORTFOLIO_ACTION_TYPES } from '@/store/modules/portfolios/portfolios.module';
import { FARMING_ACTION_TYPES } from '@/store/modules/farming/farming.module';
import { useEasyModeForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeForm';
import { useEasyModeAddLiquidity } from '@/store/modules/portfolios/easy-mode/add-liquidity/useEasyModeAddLiquidity';
import { useEasyModeAddLiquiditySteps } from '@/store/modules/portfolios/easy-mode/add-liquidity/useEasyModeAddLiquiditySteps';
import { useEasyModeWithdraw } from '@/store/modules/portfolios/easy-mode/withdraw/useEasyModeWithdraw';
import { useEasyModeWithdrawSteps } from '@/store/modules/portfolios/easy-mode/withdraw/useEasyModeWithdrawSteps';
import { useAllSteps } from '@/composables/useAllSteps';

export function usePortfoliosActions() {
  const store = useStore();
  const { easyModeForm } = useEasyModeForm();
  // ADD LIQUIDITY
  const { checkAllowance: checkAllowanceAddLiquidityTokens, doAddLiquidity } =
    useEasyModeAddLiquidity();
  const {
    addLiquiditySteps,
    prepareSteps: prepareAddLiquiditySteps,
    runSteps: runAddLiquiditySteps,
    $reset: resetAddLiquiditySteps,
  } = useEasyModeAddLiquiditySteps();
  // WITHDRAW
  const { checkAllowance: checkAllowanceWithdrawLPToken, doWithdraw } = useEasyModeWithdraw();
  const {
    withdrawSteps,
    prepareSteps: prepareWithdrawSteps,
    runSteps: runWithdrawSteps,
    $reset: resetWithdrawSteps,
  } = useEasyModeWithdrawSteps();
  const { isInProgress } = useAllSteps();

  const isSomeInProgress = computed(
    () => isInProgress.state && !withdrawSteps.inProgress && !addLiquiditySteps.inProgress,
  );

  const isStepsShown = ref(false);

  async function updatePortfoliosInfo() {
    return await store.dispatch(
      MODULE_NAMES.PORTFOLIOS + '/' + PORTFOLIO_ACTION_TYPES.UPDATE_PORTFOLIOS_INFO,
    );
  }

  async function initFarming() {
    return await store.dispatch(MODULE_NAMES.FARMING + '/' + FARMING_ACTION_TYPES.FARMING_INIT);
  }

  async function checkAllowance() {
    const allowance =
      easyModeForm.action === 'addLiquidity'
        ? checkAllowanceAddLiquidityTokens
        : checkAllowanceWithdrawLPToken;

    await allowance();
  }

  async function doOperation() {
    const operation = easyModeForm.action === 'addLiquidity' ? doAddLiquidity : doWithdraw;

    await operation();
  }

  // PORTFOLIO STEPS
  async function prepareSteps() {
    const prepare =
      easyModeForm.action === 'addLiquidity' ? prepareAddLiquiditySteps : prepareWithdrawSteps;

    await prepare();
  }

  async function runSteps() {
    const run = easyModeForm.action === 'addLiquidity' ? runAddLiquiditySteps : runWithdrawSteps;

    await run();
  }

  function onShowSteps() {
    isStepsShown.value = true;
    if (easyModeForm.action === 'addLiquidity') {
      addLiquiditySteps.isShown = true;
    } else {
      withdrawSteps.isShown = true;
    }
  }

  function onHideSteps() {
    isStepsShown.value = false;
    if (easyModeForm.action === 'addLiquidity') {
      addLiquiditySteps.isShown = false;
      addLiquiditySteps.inProgress = false;
    } else {
      withdrawSteps.isShown = false;
      withdrawSteps.inProgress = false;
    }
  }

  function resetPortfolioSteps() {
    isStepsShown.value = false;

    resetAddLiquiditySteps();
    resetWithdrawSteps();
  }

  async function sendTransaction() {
    if (ENABLE_FAKE_CARDANO_NETWORK) {
      if (isStepsShown.value) {
        resetPortfolioSteps();
      }
      await checkAllowance();
      prepareSteps();
      onShowSteps();
      runSteps();
      return;
    }

    await doOperation();
  }

  onBeforeMount(() => {
    initFarming();
    isStepsShown.value = addLiquiditySteps.isShown || withdrawSteps.isShown;
  });

  return {
    isSomeInProgress,
    isStepsShown,
    onShowSteps,
    onHideSteps,
    sendTransaction,
    updatePortfoliosInfo,
  };
}
