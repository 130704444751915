import { fetchExactTokenWeiAmountsAfterWithdraw } from '@/helpers/portfolioWithdraw.helper';
import { Portfolio, TokenInfo } from '@/sdk/entities/portfolio';
import { compareTokenAddresses, fromWei } from '@/sdk/utils';
import { normalizeWeiByMinDecimalsWhenExistCardanoToken } from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { BIG_ONE, BIG_ZERO, max, min } from '@/utils/bigNumber';
import { pCancelable } from '@/utils/promise';
import { useEasyModeForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeForm';
import { EasyModeWithdrawForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-form';
import { EasyModeWithdrawToken } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-token';
import BigNumber from 'bignumber.js';
import { ComputedRef, ref } from 'vue';
import { TOKEN_NOTICE_TYPES } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/common/constants/TOKEN_NOTICE_TYPES';

const LOGGER = {
  groupCollapsed: (...label: any[]) => {
    if (isLoggingDisabled()) return;

    console.groupCollapsed(...label);
  },
  groupEnd: () => {
    if (isLoggingDisabled()) return;

    console.groupEnd();
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (isLoggingDisabled()) return;

    console.log(message, ...optionalParams);
  },
};

const {
  easyModeForm: easyModeCommonForm,
  baseOrLpTokenAmountWei,
  selectedTokens: commonSelectedTokens,
  isValidRestAmount,
  getPortfolioAvailableTokens,
} = useEasyModeForm();
const easyModeForm = easyModeCommonForm as EasyModeWithdrawForm;
const selectedTokens = commonSelectedTokens as ComputedRef<EasyModeWithdrawToken[]>;
const feeRequestAbortRef = ref<(() => void) | null>(null);

/**
 * returns [W[]] limits in base currency wei units
 * @param withdrawAmountInLPWei [W]
 */
function calculateWithdrawLimits(
  portfolio: Portfolio,
  withdrawAmountInLPWei: BigNumber,
): BigNumber[] {
  // In BASE WEI
  const portfolioValueInBaseWei = portfolio.totalValueWithProtocolFee;
  // In BASE WEI / LP WEI
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> portfolio.lpToken.decimals
  const lpTokenPriceBaseInWei = portfolio.lpTokenPriceBase.shiftedBy(
    portfolio.baseToken.decimals - portfolio.lpToken.decimals,
  );

  LOGGER.groupCollapsed(`[PORTFOLIO:WITHDRAW] Calculate withdraw limits.`);
  const tokensNoLossLimitsInBaseWei = getPortfolioAvailableTokens(portfolio).map(tokenInfo => {
    // LP WEI * (BASE WEI / LP WEI) => BASE WEI
    const withdrawInBaseWei = withdrawAmountInLPWei.multipliedBy(lpTokenPriceBaseInWei);
    // ( V0 - D0 ) * Wi
    const tokenAmountAfterWithdrawInBaseWei = portfolioValueInBaseWei
      .minus(withdrawInBaseWei)
      .multipliedBy(tokenInfo.targetWeight);
    // Ri - ( V0 - D0 ) * Wi
    const valueMoreThanTargetInBaseWei = tokenAmountAfterWithdrawInBaseWei.isPositive()
      ? tokenInfo.baseTokenAmountEquivalent.minus(tokenAmountAfterWithdrawInBaseWei)
      : BIG_ZERO;

    const noLossLimit = max(valueMoreThanTargetInBaseWei, BIG_ZERO);

    loggingCalculateWithdrawLimits(
      tokenInfo,
      portfolio,
      noLossLimit,
      portfolioValueInBaseWei,
      withdrawAmountInLPWei,
      lpTokenPriceBaseInWei,
      withdrawInBaseWei,
      tokenAmountAfterWithdrawInBaseWei,
      valueMoreThanTargetInBaseWei,
    );

    // In BASE WEI
    return noLossLimit;
  });
  LOGGER.groupEnd();

  return tokensNoLossLimitsInBaseWei;
}

function autoSelectTokens() {
  LOGGER.groupCollapsed(`[PORTFOLIO:WITHDRAW] Auto select tokens`);

  // rawLimits in portfolio base token decimals
  const rawLimitsInBaseWei = calculateWithdrawLimits(
    easyModeForm.portfolio,
    baseOrLpTokenAmountWei.value,
  );
  resetLimitsAndSetSelectTokenNotices(rawLimitsInBaseWei);
  updateTokensNoLossLimits(rawLimitsInBaseWei);

  easyModeForm.tokens.forEach(formToken => (formToken.checked = false));
  const notZeroLimitTokens = easyModeForm.tokens.filter(formToken => formToken.noLossLimit.gt(0));
  notZeroLimitTokens.forEach(formToken => (formToken.checked = true));

  LOGGER.log('checked tokens : ', notZeroLimitTokens);
  LOGGER.groupEnd();
}

async function calculateLimits() {
  feeRequestAbortRef.value?.();
  easyModeForm.restAmount = BIG_ZERO;
  easyModeForm.restAmountToDistributeAfterFirstIteration = BIG_ZERO;
  selectedTokens.value.forEach(formToken => (formToken.lpWeiToWithdraw = BIG_ZERO));

  if (checkAndModifyFormIfCannotCalculate()) {
    return;
  }

  // In BASE WEI / LP WEI
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> easyModeForm.lpToken.decimals
  const lpTokenPriceBaseInWei = easyModeForm.portfolio.lpTokenPriceBase.shiftedBy(
    easyModeForm.portfolio.baseToken.decimals - easyModeForm.lpToken.decimals,
  );
  // LP WEI * (BASE WEI / LP WEI) => BASE WEI
  const amountToDistributeInBase = baseOrLpTokenAmountWei.value.multipliedBy(lpTokenPriceBaseInWei);

  const rawLimits = calculateWithdrawLimits(easyModeForm.portfolio, baseOrLpTokenAmountWei.value);
  resetLimitsAndSetSelectTokenNotices(rawLimits);
  updateTokensNoLossLimits(rawLimits);

  if (!selectedTokens.value.length) {
    setUndefinedWithdrawFee();
    return;
  }

  const selectedTokensLimits = selectedTokens.value.map(formToken => {
    const portfolioTokenIndex = getTokenInfoIndex(formToken.address);

    return rawLimits[portfolioTokenIndex];
  });

  const firstIterationResult = firstIteration(selectedTokensLimits, amountToDistributeInBase);
  easyModeForm.restAmountToDistributeAfterFirstIteration = fromWei(
    firstIterationResult.restAmountToDeposit, // In Base WEI
    easyModeForm.portfolio.baseToken.decimals,
  );

  // NOTE: If rest is zero after first iteration, then calculate will finish.
  if (firstIterationResult.restAmountToDeposit.isZero()) {
    loggingWithdrawCalculation(
      'Calc tokens amounts when rest amount after first iteration is zero.',
      firstIterationResult.limits,
      lpTokenPriceBaseInWei,
    );

    await updateWithdrawFeeAndAmounts(firstIterationResult.limits, rawLimits);
    return;
  }

  const secondIterationResult = secondIteration(
    firstIterationResult.limits,
    firstIterationResult.restAmountToDeposit,
  );

  // NOTE: If rest is zero after second iteration, then calculate will finish.
  if (secondIterationResult.restAmountToDeposit.isZero()) {
    const totalLimits = firstIterationResult.limits.map((limit, index) =>
      limit.plus(secondIterationResult.limits[index]),
    );

    loggingWithdrawCalculation(
      'Calc tokens amounts when rest amount after second iteration is zero.',
      totalLimits,
      lpTokenPriceBaseInWei,
    );

    await updateWithdrawFeeAndAmounts(totalLimits, rawLimits);
    return;
  }

  const thirdIterationResult = thirdIteration(
    firstIterationResult.limits,
    secondIterationResult.limits,
    secondIterationResult.restAmountToDeposit,
  );

  easyModeForm.restAmount = thirdIterationResult.restAmountToDeposit;
  if (!isValidRestAmount.value) {
    console.warn(
      'WITHDRAW:LIMITS: rest amount should be ZERO or less threshold. Need check this case.',
    );
    setUndefinedWithdrawFee();
    return;
  }

  const totalLimits = firstIterationResult.limits.map((limit, index) =>
    limit.plus(secondIterationResult.limits[index]).plus(thirdIterationResult.limits[index]),
  );

  loggingWithdrawCalculation('Calc tokens amounts.', totalLimits, lpTokenPriceBaseInWei);

  await updateWithdrawFeeAndAmounts(totalLimits, rawLimits);
}

/**
 *
 * @param currentLimits limits for selected tokens only in base currency wei units
 * @param amountToDistribute amount in base currency wei units
 */
function firstIteration(
  currentLimits: BigNumber[],
  amountToDistribute: BigNumber,
): {
  limits: BigNumber[];
  restAmountToDeposit: BigNumber;
} {
  const distributeProportionByNum = currentLimits.filter(limit => limit.gt(0)).length;
  const proportion = BIG_ONE.div(distributeProportionByNum || 1);
  let restAmountToDistributeInBaseWei = amountToDistribute;
  let limitNotReachedCounter = 0;

  const proportionLimits = selectedTokens.value.map((_, index) => {
    const proportionalAmount = amountToDistribute.multipliedBy(proportion);

    const limit = min(currentLimits[index], proportionalAmount);

    if (limit.eq(proportionalAmount)) {
      limitNotReachedCounter++;
    }

    restAmountToDistributeInBaseWei = restAmountToDistributeInBaseWei
      .minus(limit)
      .decimalPlaces(easyModeForm.portfolio.baseToken.decimals);

    // In BASE WEI
    return limit;
  });

  loggingIterationDistribution(
    'firstIteration',
    'proportion distribution',
    proportionLimits,
    restAmountToDistributeInBaseWei,
  );

  const noneLimitReached = limitNotReachedCounter === distributeProportionByNum;

  if (noneLimitReached) {
    proportionLimits[0] = proportionLimits[0].plus(restAmountToDistributeInBaseWei);
    restAmountToDistributeInBaseWei = BIG_ZERO;
  }

  if (restAmountToDistributeInBaseWei.isZero()) {
    setAllTokensNotDraggable();
  } else {
    updateTokensDraggableProperty(currentLimits, proportionLimits);
  }

  const limits = selectedTokens.value.map((_, index) => {
    if (restAmountToDistributeInBaseWei.isZero()) {
      return BIG_ZERO;
    }

    const limit = min(
      currentLimits[index].minus(proportionLimits[index]),
      restAmountToDistributeInBaseWei,
    );

    restAmountToDistributeInBaseWei = restAmountToDistributeInBaseWei
      .minus(limit)
      .decimalPlaces(easyModeForm.portfolio.baseToken.decimals);

    // In BASE WEI
    return limit;
  });
  const totalLimits = proportionLimits.map((proportionLimit, index) =>
    proportionLimit.plus(limits[index]),
  );

  loggingIterationDistribution(
    'firstIteration',
    'rest amounts distribution',
    limits,
    restAmountToDistributeInBaseWei,
  );

  loggingIterationDistribution(
    'firstIteration',
    'total amounts',
    totalLimits,
    restAmountToDistributeInBaseWei,
  );

  return {
    limits: totalLimits,
    restAmountToDeposit: restAmountToDistributeInBaseWei,
  };
}

/**
 *
 * @param limitsAfterFirstIteration limits in base currency wei units
 * @param restAmount amount in base currency wei units
 */
function secondIteration(
  limitsAfterFirstIteration: BigNumber[],
  restAmount: BigNumber,
): {
  limits: BigNumber[];
  restAmountToDeposit: BigNumber;
} {
  const selectedTokensDataSortedByVirtualReserves: {
    formToken: EasyModeWithdrawToken;
    limitInBaseWei: BigNumber;
    virtualReserveInBaseWei: BigNumber;
  }[] = selectedTokens.value
    .map((formToken, index) => {
      const tokenInfo = getTokenInfo(formToken.address);
      const price = tokenInfo.getWithdrawPrice();

      const Fx = min(price.div(tokenInfo.withdrawEMAPrice), tokenInfo.withdrawEMAPrice.div(price));

      const reserveInBaseWei = tokenInfo.baseTokenAmountEquivalent;
      const limitInBaseWei = reserveInBaseWei.minus(limitsAfterFirstIteration[index]);

      return {
        formToken,
        limitInBaseWei,
        virtualReserveInBaseWei: Fx.multipliedBy(reserveInBaseWei),
      };
    })
    .sort((a, b) => b.virtualReserveInBaseWei.minus(a.virtualReserveInBaseWei).toNumber());

  let restAmountToDistributeInBase = restAmount;

  const limitsAndFormTokens = selectedTokensDataSortedByVirtualReserves.map((tokenData, index) => {
    if (restAmountToDistributeInBase.eq(0)) {
      return { limit: BIG_ZERO, formToken: tokenData.formToken };
    }

    const notVisitedTokens = selectedTokensDataSortedByVirtualReserves.slice(index);
    const sumNotVisitedTokensVirtualReserves = notVisitedTokens.reduce(
      (acc, item) => acc.plus(item.virtualReserveInBaseWei),
      BIG_ZERO,
    );
    const WM = sumNotVisitedTokensVirtualReserves.eq(0)
      ? sumNotVisitedTokensVirtualReserves
      : tokenData.virtualReserveInBaseWei.div(sumNotVisitedTokensVirtualReserves);

    const limit = min(restAmountToDistributeInBase.multipliedBy(WM), tokenData.limitInBaseWei);

    restAmountToDistributeInBase = restAmountToDistributeInBase
      .minus(limit)
      .decimalPlaces(easyModeForm.portfolio.baseToken.decimals);
    return { limit, formToken: tokenData.formToken };
  });

  const limitsInSelectedTokensOrder = selectedTokens.value.map(
    formToken =>
      limitsAndFormTokens.find(tokenAndLimit =>
        compareTokenAddresses(tokenAndLimit.formToken.address, formToken.address),
      )!.limit,
  );

  return {
    limits: limitsInSelectedTokensOrder,
    restAmountToDeposit: restAmountToDistributeInBase,
  };
}

/**
 *
 * @param limitsAfterFirstIteration limits in base currency wei units
 * @param limitsAfterSecondIteration limits in base currency wei units
 * @param restAmount amount in base currency wei units
 */
function thirdIteration(
  limitsAfterFirstIteration: BigNumber[],
  limitsAfterSecondIteration: BigNumber[],
  restAmount: BigNumber,
): {
  limits: BigNumber[];
  restAmountToDeposit: BigNumber;
} {
  let restAmountToDistributeInBase = restAmount;

  const limits = selectedTokens.value.map((formToken, index) => {
    if (restAmountToDistributeInBase.isZero()) {
      return BIG_ZERO;
    }

    const tokenInfo = getTokenInfo(formToken.address);
    const realReserveInBaseWei = tokenInfo.baseTokenAmountEquivalent;

    const limit = min(
      restAmountToDistributeInBase,
      realReserveInBaseWei
        .minus(limitsAfterFirstIteration[index])
        .minus(limitsAfterSecondIteration[index]),
    );

    restAmountToDistributeInBase = restAmountToDistributeInBase
      .minus(limit)
      .decimalPlaces(easyModeForm.portfolio.baseToken.decimals);
    return limit;
  });

  return {
    limits, // in selected tokens order
    restAmountToDeposit: restAmountToDistributeInBase,
  };
}

function getTokenInfo(tokenAddress: string): TokenInfo {
  return getPortfolioAvailableTokens(easyModeForm.portfolio).find(tokenInfo =>
    compareTokenAddresses(tokenInfo.tokenAddress, tokenAddress),
  )!;
}

function getTokenInfoIndex(tokenAddress: string): number {
  return getPortfolioAvailableTokens(easyModeForm.portfolio).findIndex(tokenInfo =>
    compareTokenAddresses(tokenInfo.tokenAddress, tokenAddress),
  );
}

function updateTokensNoLossLimits(baseCurrencyWeiLimits: BigNumber[]): void {
  LOGGER.groupCollapsed(
    `[PORTFOLIO:WITHDRAW] Update tokens NoLossLimits for [${easyModeForm.portfolio.name}] | `,
    ' BASE : [',
    `${easyModeForm.portfolio.baseToken.symbol} | ${easyModeForm.portfolio.baseToken.decimals}`,
    ']',
  );

  getPortfolioAvailableTokens(easyModeForm.portfolio).forEach((tokenInfo, index) => {
    const formToken = easyModeForm.tokens.find(formToken =>
      compareTokenAddresses(tokenInfo.tokenAddress, formToken.address),
    )!;
    // BASE WEI / TOKEN WEI
    const withdrawPriceInWei = tokenInfo
      .getWithdrawPrice()
      .shiftedBy(tokenInfo.baseToken.decimals - tokenInfo.token.decimals);

    // In TOKEN
    formToken.noLossLimit = fromWei(
      //BASE WEI / (BASE WEI / TOKEN WEI) => TOKEN WEI
      baseCurrencyWeiLimits[index].div(withdrawPriceInWei),
      tokenInfo.token.decimals,
    );

    loggingUpdateNoLossLimit(
      tokenInfo,
      formToken,
      baseCurrencyWeiLimits[index],
      withdrawPriceInWei,
    );
  });

  LOGGER.groupEnd();
}

function resetLimitsAndSetSelectTokenNotices(rawLimits: BigNumber[]): void {
  easyModeForm.tokens.forEach(formToken => {
    formToken.amount = BIG_ZERO;
    formToken.noLossLimit = BIG_ZERO;
    formToken.notice = undefined;
    formToken.draggable = true;

    const portfolioTokenIndex = getTokenInfoIndex(formToken.address);

    if (rawLimits[portfolioTokenIndex].eq(0)) {
      formToken.notice = {
        type: TOKEN_NOTICE_TYPES.ZERO_LIMIT,
      };
    }
  });
}

function setSelectTokenNotices(rawLimits: BigNumber[]): void {
  easyModeForm.tokens.forEach(formToken => {
    const portfolioTokenIndex = getTokenInfoIndex(formToken.address);

    if (!formToken.checked && rawLimits[portfolioTokenIndex].gt(0)) {
      formToken.notice = {
        type: TOKEN_NOTICE_TYPES.SELECT,
      };
    }
  });
}

/**
 *
 * @param selectedTokensBaseWeiAmounts amounts in base currency wei units
 * @param allTokensRawLimits limits in base currency wei units
 */
async function updateWithdrawFeeAndAmounts(
  selectedTokensBaseWeiAmounts: BigNumber[],
  allTokensRawLimits: BigNumber[],
) {
  feeRequestAbortRef.value?.();
  easyModeForm.lossPercent = null;
  easyModeForm.impactError = null;
  easyModeForm.crossChainLossPercent = null;
  setTokensLoading();

  const selectedTokensLpWeiAmounts = selectedTokensBaseWeiAmounts.map(amount => {
    // In BASE WEI / LP WEI
    // NOTE: Changed `LP_TOKEN_DECIMALS` -> easyModeForm.lpToken.decimals
    const lpTokenPriceBaseInWei = easyModeForm.portfolio.lpTokenPriceBase.shiftedBy(
      easyModeForm.portfolio.baseToken.decimals - easyModeForm.lpToken.decimals,
    );
    // BASE WEI / ( BASE WEI / LP WEI ) => LP WEI
    return amount.div(lpTokenPriceBaseInWei);
  });

  selectedTokens.value.forEach((formToken, index) => {
    formToken.lpWeiToWithdraw = selectedTokensLpWeiAmounts[index];
  });

  const feeRequest = pCancelable(fetchWithdrawFeeAndAmounts(selectedTokensLpWeiAmounts));
  feeRequestAbortRef.value = feeRequest.abort;
  return feeRequest.promise
    .then(result => {
      easyModeForm.lossPercent = result.lossPercent;
      easyModeForm.impactError = result.impactError;
      easyModeForm.crossChainLossPercent = result.crossChainLossPercent;
      setTokenAmounts(result.tokenWeiAmountOuts);

      if (result.lossPercent.gt(0)) {
        setSelectTokenNotices(allTokensRawLimits);
      }
    })
    .catch(e => {
      if (!e.toString().includes('Promise has been canceled')) {
        setTokenAmounts();
        throw e;
      }
    });
}

/**
 *
 * @param selectedTokensLpWeiAmounts in LP wei units
 */
async function fetchWithdrawFeeAndAmounts(selectedTokensLpWeiAmounts: BigNumber[]): Promise<{
  lossPercent: BigNumber;
  impactError: boolean;
  tokenWeiAmountOuts: BigNumber[];
  crossChainLossPercent?: BigNumber;
}> {
  const lpTokenWeiEnteredAmount = baseOrLpTokenAmountWei.value;
  const selected = selectedTokens.value;

  let impactError = false;
  let lossPercent = BIG_ZERO;
  let tokenWeiAmountOuts = selected.map(() => BIG_ZERO);
  let crossChainLossPercent: BigNumber | undefined = undefined;

  const parameters: {
    tokenAddresses: string[];
    lptAmounts: string[];
    portfolioAddress: string;
  } = {
    tokenAddresses: [],
    lptAmounts: [],
    portfolioAddress: easyModeForm.portfolio.contractAddress,
  };
  selectedTokensLpWeiAmounts.forEach((lpWeiValue, index) => {
    const token = selected[index];
    // NOTE: We are filtering tokens with zero amount.
    if (lpWeiValue.isZero()) {
      return;
    }

    parameters.tokenAddresses.push(token.address);
    parameters.lptAmounts.push(lpWeiValue.toFixed(0));
  });

  try {
    const {
      estimatedAmountsInTokenWeiByTokensAddresses:
        selectedTokensWithNotZeroAmountsByTokensAddresses,
      crossChainFeeInLPWei: crossChainFee,
    } = await fetchExactTokenWeiAmountsAfterWithdraw(
      easyModeForm.portfolio,
      easyModeForm,
      parameters,
    );
    const crossChainFeeInLPWei = BigNumber(crossChainFee ?? 0);

    tokenWeiAmountOuts = selected.map(
      selectedToken =>
        new BigNumber(
          selectedTokensWithNotZeroAmountsByTokensAddresses[selectedToken.address] ?? 0,
        ),
    );
    const lpTokenWeiEquivalentAmountOut = tokenWeiAmountOuts.reduce(
      (acc, amountOutInTokenWei, index) => {
        const tokenInfo = getTokenInfo(selected[index].address);
        // In LP WEI / TOKEN WEI
        // NOTE: Changed `LP_TOKEN_DECIMALS` -> easyModeForm.lpToken.decimals
        const tokenPriceLPInWei = tokenInfo
          .getWithdrawPrice() // BASE / TOKEN
          .div(easyModeForm.portfolio.lpTokenPriceBase) // BASE / LP
          .shiftedBy(easyModeForm.lpToken.decimals - tokenInfo.token.decimals);

        // TOKEN WEI * ( LP WEI / TOKEN WEI ) => LP WEI
        const lpWeiEquivalent = amountOutInTokenWei.multipliedBy(tokenPriceLPInWei);
        return acc.plus(lpWeiEquivalent);
      },
      BIG_ZERO,
    );
    const lpTokenWeiEquivalentAmountOutWithCrossChainFee =
      lpTokenWeiEquivalentAmountOut.plus(crossChainFeeInLPWei);

    lossPercent = max(
      BIG_ONE.minus(
        lpTokenWeiEquivalentAmountOutWithCrossChainFee.div(lpTokenWeiEnteredAmount),
      ).multipliedBy(100),
      0,
    );

    crossChainLossPercent = crossChainFee
      ? max(crossChainFeeInLPWei.div(lpTokenWeiEnteredAmount).multipliedBy(100), BIG_ZERO)
      : undefined;

    // LOSS
    loggingLossPercent(
      lossPercent,
      lpTokenWeiEquivalentAmountOut,
      tokenWeiAmountOuts,
      selected,
      lpTokenWeiEnteredAmount,
    );

    // CROSS CHAIN LOSS
    loggingCrossChainLossPercent(
      crossChainLossPercent,
      lpTokenWeiEquivalentAmountOut,
      crossChainFee,
      crossChainFeeInLPWei,
      lpTokenWeiEquivalentAmountOutWithCrossChainFee,
      lpTokenWeiEnteredAmount,
    );
  } catch (e) {
    console.debug(e);
    impactError = true;
  }

  return {
    lossPercent,
    impactError,
    tokenWeiAmountOuts,
    crossChainLossPercent,
  };
}

/**
 *
 * @param selectedTokensAmountsWei amounts in token wei units
 */
function setTokenAmounts(selectedTokensAmountsWei?: BigNumber[]): void {
  selectedTokensAmountsWei ||= selectedTokens.value.map(() => BIG_ZERO);

  selectedTokens.value.forEach((formToken, index) => {
    const tokenInfo = getTokenInfo(formToken.address);
    const selectedTokenAmountsWei = selectedTokensAmountsWei![index];
    formToken.amount = fromWei(
      normalizeWeiByMinDecimalsWhenExistCardanoToken(selectedTokenAmountsWei, tokenInfo.token),
      tokenInfo.token.decimals,
    );
  });
}

function setTokensLoading(): void {
  selectedTokens.value.forEach(formToken => {
    formToken.amount = null;
  });
}

function checkAndModifyFormIfCannotCalculate(): boolean {
  if (baseOrLpTokenAmountWei.value.eq(0)) {
    easyModeForm.tokens.forEach(token => {
      token.amount = BIG_ZERO;

      token.notice = undefined;
    });

    setUndefinedWithdrawFee();

    return true;
  }

  return false;
}

function setUndefinedWithdrawFee(): void {
  easyModeForm.lossPercent = undefined;
  easyModeForm.impactError = undefined;
  easyModeForm.crossChainLossPercent = undefined;
}

function updateTokensDraggableProperty(
  selectedTokensLimits: BigNumber[],
  proportionLimits: BigNumber[],
): void {
  selectedTokens.value.forEach((formToken, index) => {
    formToken.draggable = !selectedTokensLimits[index].eq(proportionLimits[index]);
  });
}

function setAllTokensNotDraggable(): void {
  selectedTokens.value.forEach(formToken => {
    formToken.draggable = false;
  });
}

export function useEasyModeWithdrawCalculation() {
  return {
    autoSelectTokens,
    calculateLimits,
  };
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].EASY_MODE_WITHDRAW;
}

function loggingWithdrawCalculation(
  groupTitle: string,
  amountsInBaseWei: BigNumber[],
  lpTokenPriceBaseInWei: BigNumber,
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed(`[PORTFOLIO:WITHDRAW]: ${groupTitle} `);
  const baseTokenDecimals = easyModeForm.portfolio.baseToken.decimals;
  console.log('entered amount of LP token in WEI : ', baseOrLpTokenAmountWei.value.toString());
  console.log('priceInUSD [USD/BASE]: ', easyModeForm.portfolio.priceInUSD.toString());
  console.log('lpTokenPriceBase [BASE WEI / LP WEI]: ', lpTokenPriceBaseInWei.toString());
  console.log(`BASE: [ ${easyModeForm.portfolio.baseToken.symbol} | ${baseTokenDecimals} ]`);
  console.table(
    selectedTokens.value.map((token, index) => {
      const tokenInfo = getTokenInfo(token.address);
      // In BASE WEI
      const amountInBaseWei = amountsInBaseWei[index];
      // BASE WEI / (BASE WEI / LP WEI) => LP WEI
      const amountInLPWei = amountInBaseWei.div(lpTokenPriceBaseInWei);
      const withdrawPrice = tokenInfo.getWithdrawPrice();
      // BASE WEI / TOKEN WEI
      const withdrawPriceInWei = withdrawPrice.shiftedBy(
        baseTokenDecimals - tokenInfo.token.decimals,
      );
      const amountInTokenWei = amountInBaseWei.div(withdrawPriceInWei);
      const amountInUSD = amountInBaseWei
        .multipliedBy(easyModeForm.portfolio.priceInUSD)
        .shiftedBy(-baseTokenDecimals);
      return {
        withdrawPrice: withdrawPrice.toString(),
        withdrawPriceInWei: withdrawPriceInWei.toString(),
        amountInBaseWei: amountInBaseWei.toString(),
        amountInLPWei: amountInLPWei.toString(),
        // NOTE: Changed `LP_TOKEN_DECIMALS` -> easyModeForm.lpToken.decimals
        amountInLP: amountInLPWei.shiftedBy(-easyModeForm.lpToken.decimals).toString(),
        amountInUSD: amountInUSD.toString(),
        amountInTokenWei: amountInTokenWei.toString(),
        amount: amountInTokenWei.shiftedBy(-tokenInfo.token.decimals).toString(),
        token: tokenInfo.token.symbol,
        decimals: tokenInfo.token.decimals,
      };
    }),
  );
  console.groupEnd();
}

function loggingIterationDistribution(
  iterationName: string,
  distributionName: string,
  limitsInBaseWei: BigNumber[],
  restAmountAfterDistributeInBaseWei: BigNumber,
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed(`[PORTFOLIO:WITHDRAW] ${iterationName} | ${distributionName} `);
  console.table(
    selectedTokens.value.map((formToken, index) => {
      const tokenInfo = getTokenInfo(formToken.address);
      const baseToken = tokenInfo.baseToken;
      const token = tokenInfo.token;
      const withdrawPrice = tokenInfo.getWithdrawPrice();
      // BASE WEI / TOKEN WEI
      const withdrawPriceInWei = withdrawPrice.shiftedBy(baseToken.decimals - token.decimals);
      const limitInBaseWei = limitsInBaseWei[index];
      const limitInTokenWei = limitInBaseWei.div(withdrawPriceInWei);
      return {
        'withdraw price [BASE / TOKEN]': withdrawPrice.toString(),
        'withdraw price [BASE WEI / TOKEN WEI]': withdrawPriceInWei.toString(),
        'amount [TOKEN WEI]': limitInTokenWei.toString(),
        'amount [TOKEN]': limitInTokenWei.shiftedBy(-token.decimals).toString(),
        token: token.symbol,
        decimals: token.decimals,
        'amount [BASE WEI]': limitInBaseWei.toString(),
        'amount [BASE]': limitInBaseWei.shiftedBy(-baseToken.decimals).toString(),
        'base token': baseToken.symbol,
        'base token decimals': baseToken.decimals,
      };
    }),
  );
  console.log(
    `${iterationName} | ${distributionName} | rest amount [BASE WEI] : `,
    restAmountAfterDistributeInBaseWei.toString(),
  );
  console.groupEnd();
}

function loggingCalculateWithdrawLimits(
  tokenInfo: TokenInfo,
  portfolio: Portfolio,
  noLossLimit: BigNumber,
  portfolioValueInBaseWei: BigNumber,
  withdrawAmountInLPWei: BigNumber,
  lpTokenPriceBaseInWei: BigNumber,
  withdrawInBaseWei: BigNumber,
  tokenAmountAfterWithdrawInBaseWei: BigNumber,
  valueMoreThanTargetInBaseWei: BigNumber,
) {
  if (isLoggingDisabled()) return;

  const token = tokenInfo.token;
  const baseToken = tokenInfo.baseToken;
  // BASE / TOKEN
  const withdrawPriceBase = tokenInfo.getWithdrawPrice();
  // BASE WEI / TOKEN WEI
  const withdrawPriceBaseInWei = withdrawPriceBase.shiftedBy(baseToken.decimals - token.decimals);
  const noLossLimitInTokenWei = noLossLimit.div(withdrawPriceBaseInWei);

  console.groupCollapsed(
    `[PORTFOLIO:WITHDRAW] Limit [${token.symbol} | ${token.decimals}] : `,
    noLossLimitInTokenWei.toString(),
    ` | ${noLossLimitInTokenWei.shiftedBy(-token.decimals)}`,
  );
  console.log('Ri - ( V0 - D0 ) * Wi');
  console.table({
    'Ri [ BASE WEI ]': tokenInfo.baseTokenAmountEquivalent.toString(),
    'V0 [ BASE WEI ]': portfolioValueInBaseWei.toString(),
    'D0 [ LP WEI ]': withdrawAmountInLPWei.toString(),
    'lpTokenPriceBase [ BASE WEI / LP WEI ]': lpTokenPriceBaseInWei.toString(),
    'D0 [ BASE WEI ]': withdrawInBaseWei.toString(),
    'Wi [ SHARE ]': tokenInfo.targetWeight.toString(),
    '(V0 - D0) * Wi [ BASE WEI ]': tokenAmountAfterWithdrawInBaseWei.toString(),
    'Ri - ( V0 - D0 ) * Wi [ BASE WEI ]': valueMoreThanTargetInBaseWei.toString(),
    'withdraw price [BASE WEI / TOKEN WEI]': withdrawPriceBaseInWei.toString(),
    'Ri - ( V0 - D0 ) * Wi [TOKEN WEI]': valueMoreThanTargetInBaseWei
      .div(withdrawPriceBaseInWei)
      .toString(),
    '': '',
    'Ri [ BASE ]': tokenInfo.baseTokenAmountEquivalent.shiftedBy(-baseToken.decimals).toString(),
    'V0 [ BASE ]': portfolioValueInBaseWei.shiftedBy(-baseToken.decimals).toString(),
    'D0 [ LP ]': withdrawAmountInLPWei.toString(),
    'lpTokenPriceBase [ BASE / LP ]': portfolio.lpTokenPriceBase.toString(),
    'D0 [ BASE ]': withdrawInBaseWei.shiftedBy(-baseToken.decimals).toString(),
    'Wi [ % ]': tokenInfo.targetWeight.multipliedBy(100).toString(),
    '(V0 - D0) * Wi [ BASE ]': tokenAmountAfterWithdrawInBaseWei
      .shiftedBy(-baseToken.decimals)
      .toString(),
    'Ri - ( V0 - D0 ) * Wi [ BASE ]': valueMoreThanTargetInBaseWei
      .shiftedBy(-baseToken.decimals)
      .toString(),
    'withdraw price [ BASE / TOKEN ]': withdrawPriceBase.toString(),
    'Ri - ( V0 - D0 ) * Wi [ TOKEN ]': valueMoreThanTargetInBaseWei
      .div(withdrawPriceBaseInWei)
      .shiftedBy(-token.decimals)
      .toString(),
  });
  console.groupEnd();
}

function loggingUpdateNoLossLimit(
  tokenInfo: TokenInfo,
  formToken: EasyModeWithdrawToken,
  baseCurrencyWeiLimit: BigNumber,
  withdrawPriceInWei: BigNumber,
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed(
    `[WITHDRAW] noLossLimit [${tokenInfo.token.symbol} | ${tokenInfo.token.decimals}] : `,
    formToken.noLossLimit.toString(),
  );
  console.log('limit [ BASE WEI ] : ', baseCurrencyWeiLimit.toString());
  console.log('withdraw price [ BASE / TOKEN ] : ', tokenInfo.getWithdrawPrice().toString());
  console.log('withdraw price [ BASE WEI / TOKEN WEI ] : ', withdrawPriceInWei.toString());
  console.log(
    'noLossLimit [ TOKEN WEI ] : ',
    baseCurrencyWeiLimit.div(withdrawPriceInWei).toString(),
  );
  console.log('noLossLimit [ TOKEN ] : ', formToken.noLossLimit.toString());
  console.groupEnd();
}

function loggingLossPercent(
  lossPercent: BigNumber,
  lpTokenWeiEquivalentAmountOut: BigNumber,
  tokenWeiAmountOuts: BigNumber[],
  selected: EasyModeWithdrawToken[],
  lpTokenWeiEnteredAmount: BigNumber,
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed('[WITHDRAW] loss percent : ', lossPercent.toString());
  console.log('lpTokenWeiEquivalentAmountOut : ', lpTokenWeiEquivalentAmountOut.toString());
  const baseTokenDecimals = easyModeForm.portfolio.baseToken.decimals;
  // In BASE WEI / LP WEI
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> easyModeForm.lpToken.decimals
  const lpTokenPriceBaseInWei = easyModeForm.portfolio.lpTokenPriceBase.shiftedBy(
    baseTokenDecimals - easyModeForm.lpToken.decimals,
  );
  console.log('lpTokenPriceBase [BASE WEI / LP WEI]: ', lpTokenPriceBaseInWei.toString());
  const priceInUSD = easyModeForm.portfolio.priceInUSD;
  console.log('priceInUSD [USD/BASE]: ', priceInUSD.toString());
  console.log(`BASE: [ ${easyModeForm.portfolio.baseToken.symbol} | ${baseTokenDecimals} ]`);
  console.table(
    tokenWeiAmountOuts.map((amountOutInTokenWei, index) => {
      const tokenInfo = getTokenInfo(selected[index].address);
      const withdrawPrice = tokenInfo.getWithdrawPrice();
      // BASE WEI / TOKEN WEI
      const withdrawPriceInWei = withdrawPrice.shiftedBy(
        baseTokenDecimals - tokenInfo.token.decimals,
      );
      const amountInBaseWei = amountOutInTokenWei.multipliedBy(withdrawPriceInWei);
      const amountInLPWei = amountInBaseWei.div(lpTokenPriceBaseInWei);
      const amountInUSD = amountInBaseWei.multipliedBy(priceInUSD).shiftedBy(-baseTokenDecimals);
      return {
        withdrawPrice: withdrawPrice.toString(),
        withdrawPriceInWei: withdrawPriceInWei.toString(),
        amountInBaseWei: amountInBaseWei.toString(),
        amountInLPWei: amountInLPWei.toString(),
        // NOTE: Changed `LP_TOKEN_DECIMALS` -> easyModeForm.lpToken.decimals
        amountInLP: amountInLPWei.shiftedBy(-easyModeForm.lpToken.decimals).toString(),
        amountInUSD: amountInUSD.toString(),
        amountInTokenWei: amountOutInTokenWei.toString(),
        amount: amountOutInTokenWei.shiftedBy(-tokenInfo.token.decimals).toString(),
        token: tokenInfo.token.symbol,
        decimals: tokenInfo.token.decimals,
      };
    }),
  );
  console.log('lpTokenWeiEnteredAmount : ', lpTokenWeiEnteredAmount.toString());
  console.groupEnd();
}

function loggingCrossChainLossPercent(
  crossChainLossPercent: BigNumber | undefined,
  lpTokenWeiEquivalentAmountOut: BigNumber,
  crossChainFee: string | undefined,
  crossChainFeeInLPWei: BigNumber,
  lpTokenWeiEquivalentAmountOutWithCrossChainFee: BigNumber,
  lpTokenWeiEnteredAmount: BigNumber,
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed(
    '[WITHDRAW] cross chain loss percent : ',
    crossChainLossPercent?.toString(),
  );
  console.log('LP equivalent amount out [ LP WEI ] : ', lpTokenWeiEquivalentAmountOut.toString());
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> easyModeForm.lpToken.decimals
  console.log(
    'LP equivalent amount out [ LP ] ',
    lpTokenWeiEquivalentAmountOut.shiftedBy(-easyModeForm.lpToken.decimals).toString(),
  );
  console.log(
    'cross chain fee [ LP WEI ] : ',
    crossChainFee ? crossChainFeeInLPWei.toString() : undefined,
  );
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> easyModeForm.lpToken.decimals
  console.log(
    'cross chain fee [ LP ] : ',
    crossChainFee
      ? crossChainFeeInLPWei.shiftedBy(-easyModeForm.lpToken.decimals).toString()
      : undefined,
  );
  console.log(
    'LP equivalent amount out + cross chain fee [ LP WEI ] : ',
    lpTokenWeiEquivalentAmountOutWithCrossChainFee.toString(),
  );
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> easyModeForm.lpToken.decimals
  console.log(
    'LP equivalent amount out + cross chain fee [ LP ] : ',
    lpTokenWeiEquivalentAmountOutWithCrossChainFee
      .shiftedBy(-easyModeForm.lpToken.decimals)
      .toString(),
  );
  console.log('LP entered amount [ LP WEI ]: ', lpTokenWeiEnteredAmount.toString());
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> easyModeForm.lpToken.decimals
  console.log(
    'LP entered amount [ LP ]: ',
    lpTokenWeiEnteredAmount.shiftedBy(-easyModeForm.lpToken.decimals).toString(),
  );
  console.groupEnd();
}
