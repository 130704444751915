import BigNumber from 'bignumber.js';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { fromWei } from '@/sdk/utils';
import { BIG_ZERO } from '@/utils/bigNumber';
import { Portfolio } from '@/sdk/entities/portfolio';
import { useEasyModeForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeForm';

const { easyModeForm } = useEasyModeForm();

export function useStakedValue() {
  const { state } = useStore();

  return computed(() => {
    // NOTE: Changed `LP_TOKEN_DECIMALS` -> easyModeForm.portfolio.lpToken.decimals
    return fromWei(
      state.farming.lp[easyModeForm.portfolio.lpTokenAddress]?.deposited || BIG_ZERO,
      easyModeForm.portfolio.lpToken.decimals,
    );
  });
}

export function farmStakedInUsd(portfolio: Portfolio) {
  const { state } = useStore();
  // NOTE: Changed `LP_TOKEN_DECIMALS` -> portfolio.lpToken.decimals
  const stakedValue = fromWei(
    state.farming.lp[portfolio.lpTokenAddress]?.deposited || BIG_ZERO,
    portfolio.lpToken.decimals,
  );
  return portfolio.getLpTokenPriceUSD().multipliedBy(stakedValue);
}

export function portfolioLPToUsd(amountLP: BigNumber, portfolio: Portfolio) {
  return portfolio.getLpTokenPriceUSD().multipliedBy(amountLP);
}
