import BigNumber from 'bignumber.js';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { NETWORK_SUPPORTS_FARMS } from '@/helpers/supporting-features-in-network';
import { SortDirection } from '@/utils/types';

export function usePortfoliosSorting() {
  const store = useStore();

  const isFarmReady = computed(() => NETWORK_SUPPORTS_FARMS && store.state.farming.isStoreReady);

  function getFarmByPortfolio(portfolio) {
    return store.state.farming.lp[portfolio.lpTokenAddress];
  }

  function portfolioAprForSorting(portfolio) {
    if (isFarmReady.value) {
      const portfolioFarm = getFarmByPortfolio(portfolio);
      return portfolioFarm ? portfolioFarm.printIPR : portfolio.printIPR;
    }
  }

  function sortPortfoliosBy(portfolios, sortBy, sortDirection: SortDirection) {
    return [
      ...portfolios.sort((prev, next) => {
        let prevValue, nextValue;

        switch (sortBy) {
          case 'liquidityInUSD':
            prevValue = prev.getValueInUSD(prev.portfolioTotalValueBase.toExact());
            nextValue = next.getValueInUSD(next.portfolioTotalValueBase.toExact());
            break;
          case 'apr':
            prevValue = new BigNumber(portfolioAprForSorting(prev));
            nextValue = new BigNumber(portfolioAprForSorting(next));
            break;
          case 'feesEarned30d':
            prevValue = new BigNumber(prev.fee30.toExact());
            nextValue = new BigNumber(next.fee30.toExact());
            break;
          default:
            break;
        }

        if (sortDirection === 'desc') {
          return nextValue - prevValue;
        } else {
          return prevValue - nextValue;
        }
      }),
    ];
  }

  return {
    sortPortfoliosBy,
  };
}
