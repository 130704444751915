export enum PORTFOLIOS_FILTER_BY {
  'all' = 'all',
  'local' = 'local',
  'crosschain' = 'crosschain',
}

export const PORTFOLIO_ACTIONS = {
  ADD_LIQUIDITY: 'addLiquidity',
  WITHDRAW: 'withdraw',
};
