<template>
  <Callout content-class="tooltip-callout-container" :type="type">
    <span>
      <slot></slot>
    </span>
    <TooltipIcon class="callout-tooltip" :tooltip="tooltip" :color="type" inline />
  </Callout>
</template>

<script>
import Callout from './Callout';
import TooltipIcon from './tooltip/TooltipIcon.vue';
export default {
  name: 'CalloutWithTooltip',
  components: { Callout, TooltipIcon },
  props: {
    type: {
      validator(value) {
        return ['success', 'warning', 'danger'].includes(value);
      },
    },
    tooltip: String,
  },
};
</script>

<style>
.callout-tooltip {
  min-width: fit-content !important;
}
</style>
