import BigNumber from 'bignumber.js';
import { computed, ComputedRef, unref } from 'vue';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { TRANSACTION_FEE_NATIVE } from '@/constants/TRANSACTION_FEE_NATIVE';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { BIG_ZERO, max } from '@/utils/bigNumber';
import { MayBeRef } from '@/helpers/utils';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { TokenInfo } from '@/sdk/entities/portfolio';
import { toFixedWei } from '@/sdk/utils';
import {
  calculateMinADAAmountIntoCardanoWhenOnlyBridgeFromCardano,
  normalizeAmountByMinDecimals,
} from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useBalances } from '@/store/modules/tokens/useBalances';

/**
 Returns sum of user portfolio tokens balances in base token relative units equivalent
 */
export function useTokensBalances(
  {
    useDepositPrice,
    countHowManyCanBridgeFromCardano,
  }: {
    useDepositPrice: boolean;
    countHowManyCanBridgeFromCardano?: number;
  } = {
    useDepositPrice: false,
  },
) {
  const { balances: allBalances } = useBalances();
  const { isPresentTokenIntoNetwork } = useTokens();

  const getTokenBalancesWei = (tokens: MayBeRef<TokenInfo[]>): ComputedRef<BigNumber> => {
    const balances: ComputedRef<Record<string, { balance: TokenAmount }>> = computed(
      () => allBalances ?? {},
    );

    return computed(() =>
      unref(tokens).reduce((sumBalance, tokenInfo) => {
        let tokenBalance =
          balances.value[tokenInfo.token.unwrapWETH().symbol!]?.balance.raw.toString();

        if (!tokenBalance) {
          loggingNoTokenBalance(tokenInfo, tokenBalance);

          return sumBalance;
        }

        if (tokenInfo.token.unwrapWETH().isETHToken()) {
          let tokenBalanceWithoutFeeInToken = BIG_ZERO;

          const tokenBalanceInToken = BigNumber(tokenBalance).shiftedBy(
            -tokenInfo.token.unwrapWETH().decimals,
          );

          // NOTE:
          // When ADA and WSC disabled
          if (!ENABLE_FAKE_CARDANO_NETWORK) {
            tokenBalanceWithoutFeeInToken = max(
              tokenBalanceInToken.minus(TRANSACTION_FEE_NATIVE),
              0,
            );
          }

          // NOTE:
          // When ADA and WSC enabled
          if (
            ENABLE_FAKE_CARDANO_NETWORK &&
            isPresentTokenIntoNetwork(
              tokenInfo.token.unwrapWETH().symbol!,
              +DEFAULT_CARDANO_CHAIN_ID,
            )
          ) {
            tokenBalanceWithoutFeeInToken = max(
              tokenBalanceInToken.minus(
                calculateMinADAAmountIntoCardanoWhenOnlyBridgeFromCardano(
                  countHowManyCanBridgeFromCardano ?? 1,
                ),
              ),
              0,
            );

            tokenBalanceWithoutFeeInToken = normalizeAmountByMinDecimals(
              tokenBalanceWithoutFeeInToken.toString(),
              tokenInfo.token.unwrapWETH(),
            );
          }

          tokenBalance = tokenBalanceWithoutFeeInToken
            .shiftedBy(tokenInfo.token.unwrapWETH().decimals)
            .toFixed();
        }

        const price = useDepositPrice ? tokenInfo.getDepositPrice() : tokenInfo.price;

        // In BASE WEI
        const baseTokenWeiEquivalent = toFixedWei(
          new BigNumber(tokenBalance)
            .multipliedBy(price)
            .shiftedBy(tokenInfo.baseToken.decimals - tokenInfo.token.decimals),
        );

        loggingTokenBalance(
          tokenInfo,
          tokenBalance,
          useDepositPrice,
          price,
          baseTokenWeiEquivalent,
        );

        return sumBalance.plus(baseTokenWeiEquivalent);
      }, BIG_ZERO),
    );
  };

  return {
    getTokenBalancesWei,
  };
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].EASY_MODE_BALANCES;
}

function loggingNoTokenBalance(tokenInfo: TokenInfo, tokenBalance: string) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed(
    `=== Balance ${tokenInfo.token.symbol} | ${tokenInfo.token.unwrapWETH().symbol} ===`,
  );
  console.log(`tokenBalance (raw): `, tokenBalance);
  console.groupEnd();
}

function loggingTokenBalance(
  tokenInfo: TokenInfo,
  tokenBalance: string,
  useDepositPrice: boolean,
  price: BigNumber,
  baseTokenWeiEquivalent: BigNumber,
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed(
    `=== Balance ${tokenInfo.token.symbol} | ${tokenInfo.token.unwrapWETH().symbol} ===`,
  );
  console.log(`token isETH : `, tokenInfo.token.unwrapWETH().isETHToken());
  console.log(`token : `, tokenInfo.token.address);
  console.log(`tokenBalance (raw) : `, tokenBalance);
  console.log(
    `${tokenInfo.token.symbol} ${useDepositPrice ? 'deposit' : ''} price `,
    `in ${tokenInfo.baseToken.symbol} : `,
    price.toString(),
  );
  console.log(`baseTokenWeiEquivalent : `, baseTokenWeiEquivalent.toString());
  console.groupEnd();
}
