import { computed } from 'vue';
import { useStore } from 'vuex';
import { NETWORK_SUPPORTS_FARMS } from '@/helpers/supporting-features-in-network';
import { BIG_ZERO } from '@/utils/bigNumber';
import { PORTFOLIOS_FILTER_BY } from '@/views/pages/liquidity/portfolios/portfolio/models/constants';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';

const filterByPortfoliosFilterFn: Record<PORTFOLIOS_FILTER_BY, (portfolio) => boolean> = {
  [PORTFOLIOS_FILTER_BY.all]: portfolio => !!portfolio,
  [PORTFOLIOS_FILTER_BY.local]: portfolio => portfolio.type === PortfolioSource.PORTFOLIO_LOCALE,
  [PORTFOLIOS_FILTER_BY.crosschain]: portfolio =>
    portfolio.type === PortfolioSource.PORTFOLIO_CROSSCHAIN,
};

export function usePortfoliosFiltering() {
  const store = useStore();

  const isFarmReady = computed(() => NETWORK_SUPPORTS_FARMS && store.state.farming.isStoreReady);

  function portfolioFarmStaked(portfolio) {
    if (isFarmReady.value) {
      return store.state.farming.lp[portfolio.lpTokenAddress]?.deposited ?? BIG_ZERO;
    }
    return BIG_ZERO;
  }

  function filterByPortfoliosFilter(portfolios, portfoliosFilterBy: PORTFOLIOS_FILTER_BY) {
    return portfolios.filter(
      filterByPortfoliosFilterFn[portfoliosFilterBy] ??
        filterByPortfoliosFilterFn[PORTFOLIOS_FILTER_BY.all],
    );
  }

  function filterByStakeOnly(portfolios) {
    return portfolios.filter(item => {
      return item.getBalanceOfLp().isGreaterThan(0) || portfolioFarmStaked(item).isGreaterThan(0);
    });
  }

  function filterBySearchText(portfolios, searchFilter: string | undefined) {
    return [
      ...portfolios.filter(portfolio => {
        const portfoliosMatchingByName = portfolio.name.toLowerCase().includes(searchFilter);
        const portfoliosMatchingByTokens = portfolio.tokens.find(token => {
          return token.token.unwrapWETH().symbol.toLowerCase().includes(searchFilter);
        });
        const portfoliosMatchingByCrossChainTokens = portfolio.crossChainTokens.find(token => {
          return token.token.unwrapWETH().symbol.toLowerCase().includes(searchFilter);
        });
        return (
          portfoliosMatchingByName ||
          portfoliosMatchingByTokens ||
          portfoliosMatchingByCrossChainTokens
        );
      }),
    ];
  }

  return {
    filterByStakeOnly,
    filterByPortfoliosFilter,
    filterBySearchText,
  };
}
