import { computed } from 'vue';
import { useActiveTokensBalance } from './useActiveTokensBalance';
import { useSelectedTokensBalance } from './useSelectedTokensBalance';
import { useEasyModeForm } from './useEasyModeForm';
import { EasyModeAddLiquidityForm } from '../models/easy-mode-form';

export function useEasyModeAddLiquidityBalanceErrors() {
  const { easyModeForm: easyModeFormAddLiquidity } = useEasyModeForm();
  const easyModeForm = easyModeFormAddLiquidity as EasyModeAddLiquidityForm;

  const allBalancesSumInBaseToken = useActiveTokensBalance(true);
  const selectedBalancesSumInBaseToken = useSelectedTokensBalance(true);

  const totalGTAllBalancesSum = computed(() =>
    easyModeForm.baseTokenAmount.gt(allBalancesSumInBaseToken.value),
  );

  const totalGTSelectedBalancesSum = computed(() =>
    easyModeForm.baseTokenAmount.gt(selectedBalancesSumInBaseToken.value),
  );

  return {
    allBalancesSumInBaseToken,
    selectedBalancesSumInBaseToken,
    isNotEnoughBalance: totalGTAllBalancesSum,
    isNotEnoughBalanceOfSelectedTokens: totalGTSelectedBalancesSum,
  };
}
