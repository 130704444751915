import { Portfolio, TokenInfo } from '@/sdk/entities/portfolio';

export const PORTFOLIO_LOGGER = (() => {
  function isLoggingDisabled() {
    return !window['BLUESHIFT_DEBUG'].PORTFOLIO_INFO;
  }

  function logging(portfolio: Portfolio) {
    if (isLoggingDisabled()) return;

    console.groupCollapsed(
      `[PORTFOLIO:INFO] ${portfolio.name} | ${portfolio.contractAddress} | ${portfolio.type} | ${portfolio.portfolioId}`,
    );
    const baseToken = portfolio.baseToken;
    console.log('baseToken: ', `${baseToken.symbol} | ${baseToken.decimals}`);
    console.log(`price [ USD / ${baseToken.symbol} ] : `, portfolio.priceInUSD.toString());
    console.log(
      `total value [ ${baseToken.symbol} ] : `,
      portfolio.portfolioTotalValueBase.toFixed(),
    );
    console.log(
      `lpToken price [ ${baseToken.symbol} / LP ] `,
      portfolio.lpTokenPriceBase.toString(),
    );
    console.log(`lpToken price [ USD / LP ] `, portfolio.getLpTokenPriceUSD().toString());
    loggingTokensInfo(portfolio.tokens);
    console.groupEnd();
  }

  function loggingTokensInfo(tokensInfo: TokenInfo[]) {
    tokensInfo.forEach(loggingTokenInfo);
  }

  function loggingTokenInfo(tokenInfo: TokenInfo) {
    const baseToken = tokenInfo.baseToken;
    const token = tokenInfo.token;

    console.groupCollapsed(`[PORTFOLIO:TOKEN] ${token.symbol} | ${token.decimals}`);
    console.log(
      'target weight : ',
      tokenInfo.targetWeight.toString(),
      ` | ${tokenInfo.targetWeight.multipliedBy(100).toString()}%`,
    );
    console.log(`amount [ ${token.symbol} ] : `, tokenInfo.amount.toFixed());
    console.log(`price [ ${baseToken.symbol} / ${token.symbol} ] : `, tokenInfo.price.toString());
    console.log(
      `depositEMAPrice [ ${baseToken.symbol} / ${token.symbol} ] : `,
      tokenInfo.depositEMAPrice.toString(),
    );
    console.groupEnd();
  }

  return {
    logging,
  };
})();
